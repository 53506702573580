.menu-items-main {
    float: left;
    width: 100%;
    padding: 10px 20px;
}

.menu-item {
    color: #333;
    font-size: 17px;
    padding-left: 18px;
    padding-top: 20px;
}

.menu-item.button {
    padding-left: 15px;
}

.menu-item:hover {
    opacity: 0.8;
}

.logo-container {
    width: 20%;
    margin-top: 10px;
    float: left;
}

.logo {
    width: 100px;
}

.menu-items-container {
    width: 74%;
    float: right;
}

.active-menu {
    color: #5bc4be;
    font-weight: bold;
}

.mobile-menu-container .active-menu {
    color: #fff;
}

.menu-bar {
    cursor: pointer;
    margin-top: 25px;
    display: none;
}

.menu-bar.active {
    color: #5bc4bfb2;
}

.mobile-menu {
    transition: opacity 0.2s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.mobile-menu.active {
    opacity: 1;
    height: auto;
}

.mobile-menu-container .btn-style {
    border: 2px solid #5bc4be;
    background-color: #fff;
    color: #5bc4be;
}

/* start media queries
--------------------------------------------- */

@media only screen and (max-width: 1220px) {
    .menu-item {
        padding-left: 16px;
    }
}

@media only screen and (max-width: 1100px) {
    .logo-container {
        width: 8%;
    }
    .menu-items-container {
        width: 92%;
    }
    .logo {
        width: 79px;
    }
}

@media only screen and (max-width: 900px) {
    .wrapper.header {
        padding-top: 14px;
    }
    .mobile-menu .menu-item {
        width: 100%;
    }
    .mobile-menu-item {
        background-color: #5bc4bfb2;
        border-bottom: 2px solid #fff;
        padding: 20px 20px;
        font-size: 20px;
        cursor: pointer;
    }
    .mobile-menu-container {
        text-align: center;
        padding-top: 20px;
    }
    .menu-bar {
        display: block;
    }
    .desktop-menu {
        display: none;
    }
}

/* end media queries
  --------------------------------------------- */