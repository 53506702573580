.home-container {
    padding-top: 80px;
    padding-bottom: 80px;
}

.welcome-text {
    font-size: 24px;
    color: #333;
    line-height: 1.6;
}

.sensors-link a {
    color: #5bc4be;
    font-weight: bold;
}

.add-sign {
    padding-left: 10px;
}

.wrapper.home {
    display: none;
}

.wrapper.home.active {
    display: block;
}

/* start media queries
--------------------------------------------- */

@media only screen and (max-width: 750px) {
    .home-container {
        padding-top: 50px;
    }
    .welcome-text {
        font-size: 20px;
    }
}

/* end media queries
  --------------------------------------------- */