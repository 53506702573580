.cmb-style {
    background-image: url('../../assets/arrow_down_gray.png');
    background-position-x: 98%;
    background-position-y: center !important;
    background-repeat: no-repeat;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    border: 1px solid #dbdbdb;
    height: 35px;
    padding-left: 10px;
    position: initial !important;
    width: 100%;
    -o-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin-top: 10px;
}