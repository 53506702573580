.room-details {
    margin-right: 16px;
}

.add-room {
    padding-left: 10px;
}

.submit-form.room{
    margin-top: 40px;
}