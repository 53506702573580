.building .list-item {
    width: 16.2%;
}

.building .list-item.address {
    width: 24%;
}

.building .list-item.date {
    width: 18%;
}

.related-apartments .list-item {
    width: 20%;
}

.building-details {
    margin-right: 16px;
}

/* start media queries
--------------------------------------------- */

@media only screen and (max-width: 922px) {
    .building .list-item {
        width: 185px;
    }
    .building .list-item.address {
        width: 300px;
    }
    .scroll-list.building {
        width: 1200px;
    }
}

/* end media queries
 --------------------------------------------- */