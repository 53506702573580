.login-form {
  max-width: 660px;
  margin: auto;
  padding: 50px 30px;
  box-shadow: 0 10px 13px 0 rgba(0, 0, 0, .2);
  margin-top: 80px;
}

.login-title {
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-label{
  margin-bottom: 10px;
  margin-top:10px;
}