body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: snow;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  display: inline;
  text-transform: inherit;
}

a {
  background-color: transparent;
  text-decoration: none;
  outline: none !important;
  color: #333;
}

/* table structure
--------------------------------------------- */

.create-table {
  display: table;
  table-layout: fixed
}

.create-row {
  display: table-row;
}

.create-cell {
  display: table-cell;
}

.full-width {
  width: 100%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

/* end table structure
--------------------------------------------- */

.position-relative {
  position: relative;
}

.display-none {
  display: none;
}

.main-container {
  max-width: 750px;
  margin: auto;
  padding: 80px 20px;
  display: none;
}

.main-container.active {
  display: block;
}

.wrapper {
  max-width: 1180px;
  position: relative;
  padding: 0 20px;
  margin: auto;
}

.wrapper.list {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  display: none;
}

.wrapper.list.active {
  display: block;
}

.list-items {
  margin-top: 20px;
  background-color: #fff;
  font-size: 16px;
}

.list-item {
  padding: 20px 15px 20px 15px;
}

.list-row:nth-child(odd) {
  background-color: #f8f8f8;
}

.list-item.title {
  background-color: #fff !important;
}

.search-box {
  width: 300px;
}

.search-box .txt-style {
  background-image: url("./assets/search_icon.png");
  background-repeat: no-repeat;
  background-size: 30px 30px;
  padding-left: 35px;
  margin-bottom: 30px;
  margin-top: 30px
}

.details-title {
  color: #333;
  font-size: 20px;
  padding-bottom: 20px;
}

.related-title {
  color: #333;
  font-size: 20px;
  padding-top: 20px;
}

.info-icon {
  padding-top: 18px;
}

.hover-icon {
  cursor: pointer;
}

.list-end {
  padding-top: 30px;
  padding-bottom: 30px;
}

.submit-form {
  padding: 20px 73px 30px 60px;
  box-shadow: 0 10px 13px 0 rgba(0, 0, 0, .2);
}

.form-input {
  padding-top: 15px;
}

.form-input-inner {
  padding-top: 10px;
}

.page-title {
  font-size: 30px;
  color: #333;
  font-weight: bold;
  padding-top: 30px;
}

.list-item.title {
  font-weight: bold;
}

.error-message {
  color: red;
}

.details-main .list-items {
  padding-bottom: 20px;
}

.details-main strong {
  padding-right: 5px;
}

.list-item.ID {
  width: 5% !important;
}

.details-block {
  display: none;
}

.details-block.active {
  display: block;
  border-top: 2px solid #5bc4be;
}

.details-inner {
  padding: 10px;
}

.details-inner strong {
  padding-right: 5px;
}

.list-row.active {
  border: 2px solid #5bc4be;
}

.btn-container {
  display: flex;
}

.btn-2 {
  margin-left: 10px;
}

.sort-icon {
  transform: rotate(90deg);
  margin-left: 21px;
  margin-top: -19px;
  cursor: pointer;
}

.reset-icon {
  padding-top: 30px;
  padding-right: 30px;
  cursor: pointer;
}

.filter select {
  margin-bottom: 30px;
  margin-top: 30px;
  padding-right: 20px;
}

.filter {
  width: 355px;
  margin-left: 30px;
  margin-bottom: 28px;
  margin-top: 28px;
}

.pagination-top {
  padding-top: 30px;
}

.page-size-filter {
  width: 90px;
}

.page-size-text {
  color: #333;
  padding-top: 6px;
  padding-right: 10px;
}

.warning-text {
  padding-top: 30px;
}

/* start media queries
--------------------------------------------- */

@media only screen and (max-width: 1220px) {
  .list-item {
    padding: 20px 9px 20px 9px;
  }
}

@media only screen and (max-width: 1195px) {
  .search-box {
    width: 285px;
  }
  .filter {
    width: 290px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .search-box {
    width: 100%;
  }

  .filter {
    width: 100%;
    margin-left: 0;
  }

  .reset-icon {
    padding-right: 0;
    float: right;
    padding-top: 15px;
  }

  .add-icon {
    margin-right: 0;
    padding-top: 10px;
  }

  .filter, .search-box .txt-style {
    margin-bottom: 0;
  }

  .building .search-box .txt-style, .buildingObjectStructure .search-box .txt-style,.room .search-box .txt-style,.apartment .search-box .txt-style {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 922px) {
  .list-item {
    padding: 20px 6px 20px 6px;
  }

  .sort-icon {
    margin-left: 30px;
    margin-top: -32px;
  }

  .scroll-list {
    overflow-x: scroll;
    width: 900px;
  }

  .wrapper.list {
    overflow: hidden;
    overflow-x: hidden;
    overflow-x: visible;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 750px) {
  .main-container {
    padding: 40px 20px;
  }

  .submit-form {
    padding: 20px 50px 30px 50px;
  }

  .page-title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .page-title {
    font-size: 20px;
  }
}

/* end media queries
--------------------------------------------- */