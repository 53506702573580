input {
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.txt-style {
  font-size: 16px;
  color: #333;
  background-color: #fff;
  height: 35px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #dbdbdb;
  margin-top: 10px;
}