.apartment .list-item{
    width:16%;
}

.apartment .list-item.address{
    width:25%;
}

.map{
    width: 200px;
    margin-top: 20px;
}

.apartment-details{
    margin-right: 16px;
}

/* start media queries
--------------------------------------------- */

@media only screen and (max-width: 922px) {
    .apartment .list-item {
        width: 185px;
    }
    .apartment .list-item.address {
        width: 630px;
    }
    .scroll-list.apartment {
        width: 1320px;
    }
}

/* end media queries
 --------------------------------------------- */