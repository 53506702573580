.sensor .list-item {
    width: 18%;
}

.sensor-details {
  margin-right: 16px;
}

/* start media queries
--------------------------------------------- */
@media only screen and (max-width: 922px) {
  
    .sensor .list-item {
      width: 170px;
    }
   
  }
/* end media queries
--------------------------------------------- */