.btn-style {
    border: 1px solid #5bc4be;
    background-color: #5bc4be;
    color: #fff;
    height: 45px;
    width: 208px;
    margin-top: 30px;
    font-size: 18px;
    cursor: pointer;
}

.btn-style:hover {
    opacity: 0.8;
}